import React, {useState} from 'react';
import {withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import styled from 'styled-components';
import {Aerenzdall, Bettendorf, Bettembourg, Dippach, EschVelok, EschSurSure, Ettelbruck, Frisange, Grevenmacher, Helperknapp, Hesperange, LFT, Leudelange, Mamer, Mersch, Mondorf, Petange, Sanem, Smarttouch, Steinsel, Troisvierges, Useldange, Vianden, Waldbillig, Weiswampach, Wincrange, SalonDesCommunes} from '../config/conditions';
import images from '../config/images';
import {SET_SHOWCOPYRIGHT, SET_SHOWHOWTO} from '../redux/actions';

const ActionButtonWrapper = styled.div`
    text-align: center;
    display: block;
    padding: ${props => props.theme.scale * 20}px ${props => props.theme.scale * 10}px;
    width: 100%;
    box-sizing: border-box; 
    text-decoration: none;
`;

const HowtoButtonWrapper = styled.div`
    margin-bottom: ${props => props.theme.scale * 30}px;
`;

const ButtonWrapper = styled.div`
    ${(props) =>
        props.theme.infoIconColor
            ? `
            color: ${props.theme.white};
            svg {
                width: ${props.theme.scale * 36}px;
                height: ${props.theme.scale * 36}px;
                color: ${props.$showcopyright ? props.theme.infoIconActiveColor : props.theme.infoIconColor };

            
                path, line {
                    stroke: ${props.$showcopyright ? props.theme.infoIconActiveColor : props.theme.infoIconColor } !important;
                }
            }
            `
            : `
            color: ${props.theme.white};
            svg {
                width: ${props.theme.scale * 36}px;
                height: ${props.theme.scale * 36}px;
                color: ${props.$showcopyright ? props.theme.shade4 : props.theme.white };
        
            
                path, line {
                    stroke: ${props.$showcopyright ? props.theme.shade4 : props.theme.white } !important;
                }
            }
    `}
`;

const ButtonWrapperBig = styled.div`
    color: ${props => props.$showcopyright ? props.theme.navHighlight : props.theme.white };
    font-size: ${props => props.theme.scale * 22}px; 
    font-family: 'MuseoSans-900';

    svg {
        width: ${props => props.theme.scale * 50}px;
        height: ${props => props.theme.scale * 50}px;
        color: ${props => props.$showcopyright ? props.theme.navHighlight : props.theme.white };

    
        path, line {
            stroke: ${props => props.$showcopyright ? props.theme.navHighlight : props.theme.white } !important;
        }
        margin-bottom:  ${props => props.theme.scale * 20}px;
        margin-top:  ${props => props.theme.scale * 20}px;
    }
`;

const ActionContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999; 
`;

const HowtoContent = styled.div`
    position: absolute;
    
    background-color: rgba(255,255,255,0.9); 
    box-sizing: border-box;
    overflow: hidden;
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';

    bottom: ${props => props.theme.scale * 40}px;
    left: ${props => props.theme.scale * 40}px;
    width: ${props => props.theme.scale * 800}px;
    height: ${props => props.theme.scale * 850}px;
`;


const CopyrightContent = styled.div`
    position: absolute;
    z-index: 999999;
    
    background-color: rgba(255,255,255,0.95); 
    padding: ${props => props.theme.scale * 36}px ${props => props.theme.scale * 40}px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: ${props => props.theme.scale * 20}px;  
    font-family: 'MuseoSans-300';

    h2 {
        font-size: ${props => props.theme.scale * 20}px;  
        font-family: 'MuseoSans-300';
        font-weight: normal;
    }


    ${props => props.theme.layout === 0 && `
        bottom: ${props.theme.scale * 60}px;
        left: ${props.theme.scale * 96}px;
        width: ${props.theme.scale * 770}px;
        height: ${props.theme.scale * 1110}px;
    `}

    ${props => props.theme.layout === 1 && `
        bottom: ${props.theme.scale * 60}px;
        left: ${props.theme.scale * 96}px;
        width: ${props.theme.scale * 770}px;
        height: ${props.theme.scale * 1110}px;
    `}

    ${props => props.theme.layout === 2 && `
        bottom: ${props.theme.scale * 40}px;
        left: ${props.theme.scale * 40}px;
        width: ${props.theme.scale * 800}px;
        height: ${props.theme.scale * 850}px;
    `}

    ${props => props.theme.layout === 3 && `
        bottom: ${props.theme.scale * 200}px;
        left: ${props.theme.scale * 280}px;
        width: ${props.theme.scale * 1580}px;
        height: ${props.theme.scale * 800}px;
    `}

    ${props => props.theme.layout === 4 && !props.$mr && `
        bottom: ${props.theme.scale * 60}px;
        left: ${props.theme.scale * 96}px;
        width: ${props.theme.scale * 770}px;
        height: ${props.theme.scale * 1410}px;
    `}

    ${props => props.theme.layout === 4 && props.$mr && `
        bottom: ${props.theme.scale * 160}px;
        left: ${props.theme.scale * 50}px;
        width: ${props.theme.scale * 820}px;
        height: ${props.theme.scale * 1310}px;
    `}
`;

const KeyboardWrapper = styled.div`
    width: 80%;
    background-color: ${props => props.theme.lightgray}; 
    border-radius: ${props => props.theme.scale * 10}px;
    padding: ${props => props.theme.scale * 10}px;
    position: absolute;
    bottom: ${props => props.theme.scale * 400}px;
    left: ${props => props.theme.scale * 40}px;
`;

const KeyboardDisplay = styled.input`
    width: 92%;
    border: none;
    font-size: ${props => props.theme.scale * 30}px; 
    font-family: 'MuseoSans-300';
    border-radius: ${props => props.theme.scale * 10}px;
    margin-bottom: ${props => props.theme.scale * 10}px; 
    padding: ${props => props.theme.scale * 10}px; 
    box-sizing: border-box;
`;

const Close = styled.div`
    cursor: Pointer;
    user-select: none;
    width: ${props => props.theme.scale * 28}px;
    height: ${props => props.theme.scale * 28}px;
    position: absolute;

    ${props => props.$mr ? `
        bottom: ${props.theme.scale * 28}px;
        right: ${props.theme.scale * 28}px;
    ` : `
        top: ${props.theme.scale * 28}px;
        right: ${props.theme.scale * 28}px;
    `}
`;

const CloseKeyboard = styled.div`
    cursor: Pointer;
    user-select: none;
    width: ${props => props.theme.scale * 50}px;
    height: ${props => props.theme.scale * 50}px;
    position: absolute;
    top: ${props => props.theme.scale * 18}px;
    right: ${props => props.theme.scale * 18}px;
`;

const ThemeIcon = styled.div`
    text-align: center;
    display: block;

    img {
        width: ${props => props.theme.scale * 34}px;
    }
`;

const ActionButton = ({ className, activeLanguage }) => {
    const [emailAdress, setEmailAdress] = useState('');
    const [emailSend, setEmailSend] = useState(false);
    const [showMail, setShowMail] = useState(false);
    const customer = useSelector(state => state.config.customer);
    const actionButton = useSelector(state => state.config.actionButton);
    const action = useSelector(state => state.config.action);
    const layout = useSelector(state => state.config.layout);
    const showCopyright = useSelector(state => state.config.showCopyright);
    const showHowto = useSelector(state => state.config.showHowto);
    const home = useSelector(state => state.config.home);
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);
    const theme = useSelector(state => state.config.theme);
    const dispatch = useDispatch();

    const copyrightClicked = () => { 
        dispatch({ type: SET_SHOWCOPYRIGHT, value: !showCopyright});
    }

    const mailClicked = () => { 
        setShowMail(!showMail);
        setEmailAdress('');
        setEmailSend(false);
    }

    const howtoClicked = () => {
        dispatch({ type: SET_SHOWHOWTO, value: !showHowto});
    }

    const onChange = (input) => {
        setEmailAdress(input);
    }
    
    const onKeyPress = async (button) => {
        if (button === "{enter}") {
            let email = document.querySelector('#email').value;
            try {
                let apiUrl = `${process.env.REACT_APP_API_URL}mailto/?city=${customer}&to=${email}&url=${action}`;
                const response = await fetch(apiUrl);
                const mailStatus = await response.json();
    
                if (mailStatus.success) {
                    setEmailSend(true);
                    setTimeout(function(){ setShowMail(false) }, 5000);
                }
            } catch (error) {
                console.log(error);
            }   
        }
    }

    let ret = "";
    const HowtoIcon = images.icons.howto;

    let infoIcon;
    if (!theme.iconSet || theme.useDefaultInfoIcons) {
        const InfoIcon = images.icons.copyright;
        infoIcon = <InfoIcon />;
    } else {
        if (showCopyright) {
            infoIcon = <ThemeIcon><img src={`images/themes/${theme.iconSet}/ic_info_active.svg`} alt="MR" /></ThemeIcon>;
        } else {
            infoIcon = <ThemeIcon><img src={`images/themes/${theme.iconSet}/ic_info.svg`} alt="MR" /></ThemeIcon>;
        }
    }

    switch (actionButton) {
        case 'copyright':
            if (theme.hideLanguage && !mobiliteReduite) {
                ret = <ButtonWrapperBig onClick={copyrightClicked} data-on="click" data-event-category="Copyright" data-event-action="open" $mr={mobiliteReduite} $showcopyright={showCopyright}>
                            {infoIcon}
                            Informations
                        </ButtonWrapperBig>;
            } else if (theme.hideLanguage && mobiliteReduite) {
                ret = <ButtonWrapperBig onClick={copyrightClicked} data-on="click" data-event-category="Copyright" data-event-action="open" $mr={mobiliteReduite} $showcopyright={showCopyright}>
                            {infoIcon}
                        </ButtonWrapperBig>;
            } else { 
                ret = <ButtonWrapper onClick={copyrightClicked} data-on="click" data-event-category="Copyright" data-event-action="open" $mr={mobiliteReduite} $showcopyright={showCopyright}>{infoIcon}</ButtonWrapper>; 
            }
            
            break;
        case 'mail':
            const MailIcon = images.icons.mail;
            ret = <ButtonWrapper onClick={mailClicked} data-on="click" data-event-category="MailTo" data-event-action="open" $mr={mobiliteReduite} $showcopyright={showCopyright}><MailIcon /></ButtonWrapper>;
            break;
        default:
            ret = "";
    }

    let output;
    switch (customer) {
        case 'esch':
            (layout === 2) ? output = <EschVelok /> : output = <Smarttouch />
            break;
        case 'mersch':
            output = <Mersch />;
            break;
        case 'petange':
            output = <Petange />;
            break;
        case 'hesperange':
            output = <Hesperange />;
            break;
        case 'dippach':
            output = <Dippach />;
            break;
        case 'mondorf':
            output = <Mondorf />;
            break;
        case 'waldbillig':
            output = <Waldbillig />;
            break;
        case 'leudelange':
            output = <Leudelange />;
            break;
        case 'aerenzdall':
            output = <Aerenzdall />;
            break;
        case 'helperknapp':
            output = <Helperknapp />;
            break;
        case 'sanem':
            output = <Sanem />;
            break;
        case 'wincrange':
            output = <Wincrange />;
            break;
        case 'mamer':
            output = <Mamer />;
            break;
        case 'troisvierges':
            output = <Troisvierges />;
            break;
        case 'bettendorf':
            output = <Bettendorf />;
            break;
        case 'vianden':
            output = <Vianden />;
            break;
        case 'steinsel':
            output = <Steinsel />;
            break;
        case 'useldange':
            output = <Useldange />;
            break;
        case 'weiswampach':
            output = <Weiswampach />;
            break;
        case 'frisange':
            output = <Frisange />;
            break;
        case 'lft':
            output = <LFT />;
            break;
        case 'salon-des-communes':
            output = <SalonDesCommunes />;
            break;
        case 'ettelbruck':
            output = <Ettelbruck />;
            break;
        case 'esch-sur-sure':
            output = <EschSurSure />;
            break;
        case 'grevenmacher':
            output = <Grevenmacher />;
            break;
        case 'bettembourg':
            output = <Bettembourg />;
            break;
        default:
            output = <Smarttouch />;
    }

    return (
        <React.Fragment>
            <ActionButtonWrapper className={className}>
                {(customer === 'esch' && layout === 2 && !home) && 
                    <HowtoButtonWrapper>
                        <ButtonWrapper onClick={howtoClicked} data-on="click" data-event-category="HowTo" data-event-action="open"><HowtoIcon /></ButtonWrapper>
                    </HowtoButtonWrapper>
                }
                {ret}
            </ActionButtonWrapper>
            {showMail && 
                <ActionContainer>
                    <KeyboardWrapper>
                        <CloseKeyboard  onClick={mailClicked}><img src={images.icons.close} alt="Close" /></CloseKeyboard>
                        {emailSend ? (
                            <h1>Mail sent!</h1>
                        ) : (
                            <React.Fragment>
                                <KeyboardDisplay type="email" defaultValue={emailAdress} id="email" />
                                <Keyboard
                                    mergeDisplay={true}
                                    display={{
                                        '{enter}': 'Submit'
                                    }}
                                    onChange={input => onChange(input)}
                                    onKeyPress={button => onKeyPress(button)}
                                />
                            </React.Fragment>
                        )}
                        
                    </KeyboardWrapper>
                </ActionContainer>
            }
            {showHowto && 
                <ActionContainer>
                    <HowtoContent>
                        <Close onClick={howtoClicked}><img src={images.icons.close} alt="Close" /></Close>
                        {activeLanguage.code === "de" ? <img src={images.placeholder.howto_de} alt="How To" /> : <img src={images.placeholder.howto_fr} alt="How To" />}
                    </HowtoContent>
                </ActionContainer>
            }
            {showCopyright && 
                <ActionContainer>
                    <CopyrightContent $mr={mobiliteReduite}>
                        <Close onClick={copyrightClicked} $mr={mobiliteReduite}><img src={images.icons.close} alt="Close" /></Close>
                        {output}
                    </CopyrightContent>
                </ActionContainer>
            }
        </React.Fragment>
    );
}

export default withLocalize(ActionButton);