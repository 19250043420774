import React from "react";
import {renderToStaticMarkup} from "react-dom/server";
import Marquee from "react-double-marquee";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {
    Navigate, Route,
    BrowserRouter as Router,
    Routes
} from "react-router-dom";
import styled, {ThemeProvider, css} from "styled-components";
import ActionButton from "../components/ActionButton";
import DateTime from "../components/DateTime";
import InactivityCheck from "../components/InactivityCheck";
import LanguageToggle from "../components/LanguageToggle";
import MobiliteReduite from "../components/MobiliteReduite";
import Navigation from "../components/Navigation";
import Slider from "../components/Slider";
import WeatherWidget from "../components/WeatherWidget";
import images from "../config/images";
import {setCustomer, setDefaultPath} from "../redux/actions";
import globalTranslations from "../translations/global.json";
import Accomodation from "./Accomodation";
import Ads from "./Ads";
import Commune from "./Commune";
import EReider from "./EReider";
import EatAndDrink from "./EatAndDrink";
import Events from "./Events";
import Explore from "./Explore";
import FloorPlan from "./FloorPlan";
import Games from "./Games";
import Health from "./Health";
import Home from "./Home";
import Hotels from "./Hotels";
import IMXPoi from "./IMXPoi";
import IMXRestaurants from "./IMXRestaurants";
import Information from "./Information";
import Jobs from "./Jobs";
import Mobility from "./Mobility";
import Photo from "./Photo";
import PointsOfInterest from "./PointsOfInterest";
import Poll from "./Poll";
import RTL from "./RTL";
import Shops from "./Shops";
import TraficInfo from "./TraficInfo";
import VideoSlider from "./VideoSlider";
import Weather from "./Weather";
import Webview from "./Webview";
import WhatToDo from "./WhatToDo";
import WhattodoRestaurants from "./WhattodoRestaurants";

// Styled Components
const MainWrapper = styled.div`
    width: ${(props) => props.theme.width * props.theme.scale}px;
    height: ${(props) => props.theme.height * props.theme.scale}px;
    display: flex;
    margin: 0 auto;
    position: relative;

    ${(props) => props.theme.backgroundImage && `background: url(${process.env.PUBLIC_URL}/images/backgrounds/${props.theme.backgroundImage}) no-repeat top left;`}
    

    ${(props) =>
        props.theme.layout === 4
            ? `
        background-color: ${props.theme.white};
        flex-direction: column;
    `
            : `
        background-color: ${props.theme.shade1};
    `}
`;

const NavigationWrapper = styled(Navigation)`
    z-index: 999;

    ${(props) =>
        props.theme.layout === 0 &&
        `
        position: absolute;
        ${
            props.$mr 
                ? `bottom: 0;`
                : `top: ${props.theme.scale * props.theme.navTop}px;`
        };
    `}

    ${(props) =>
        props.theme.layout === 2 &&
        `
        position: absolute;
        ${
            props.$mr 
                ? `bottom: 0;`
                : `top: ${props.theme.scale * props.theme.navTop}px;`
        };
    `}

    ${(props) =>
        props.theme.layout === 3 &&
        `
        position: absolute;
        top: ${props.theme.scale * 340}px;
        left: -${props.theme.scale * 224}px;
        width: ${props.theme.scale * 224}px;
        height: ${props.theme.scale * 740}px;
        overflow-y: auto;
        overflow-x: hidden;
    `}
  
    ${(props) => (props.theme.layout === 4 && !props.$mr  && !props.hideLanguage) && `
        position: absolute;
        top: ${props.theme.scale * 340}px;
        right: 0;
        height: ${props.theme.scale * 920}px;
        width: ${props.theme.scale * 161}px;
        overflow-y: auto;
        overflow-x: hidden;
    `}

    ${(props) => (props.theme.layout === 4 && !props.$mr  && props.hideLanguage) && `
        position: absolute;
        top: ${props.theme.scale * 180}px;
        right: 0;
        height: ${props.theme.scale * 1100}px;
        width: ${props.theme.scale * 161}px;
        overflow-y: auto;
        overflow-x: hidden;
    `}

    ${(props) => (props.theme.layout === 4 && !props.$mr  && props.hideLanguage) && `
        position: absolute;
        top: ${props.theme.scale * 180}px;
        right: 0;
        height: ${props.theme.scale * 1100}px;
        width: ${props.theme.scale * 162}px;
        overflow-y: auto;
        overflow-x: hidden;
    `}

    ${(props) =>
        props.theme.layout === 4 &&
        props.$mr  &&
        `
          position: absolute;
          top: auto;
          bottom: 0;
          right: 0;
          height: ${props.theme.scale * 920}px;
          width: ${props.theme.scale * 161}px;
          overflow-y: auto;
          overflow-x: hidden;
      `}
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const Main = styled.div`
    width: ${(props) => props.theme.scale * props.theme.contentWidth}px;
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column;
    z-index: 2;

    ${(props) =>
        props.theme.layout === 3 &&
        `
        position: absolute;
        bottom: ${props.theme.scale * props.theme.contentPosBottom}px;
        left: ${props.theme.scale * props.theme.contentPosLeft}px;
        width: calc(100% - ${props.theme.scale * 224}px);
        height: 100%;
    `}

    ${(props) =>
        props.theme.layout === 4 &&
        `
          width: 100%;
      `}
`;

const Aside = styled.div`
    width: ${(props) => props.theme.scale * props.theme.asideWidth}px;
    flex: 0 0 ${(props) => props.theme.scale * props.theme.asideWidth}px;

    position: relative;
    z-index: 1;

    ${(props) =>
        props.theme.layout === 4
            ? `
        background-color: ${props.theme.shade1};
        height: 100%;
        box-sizing: border-box;
    `
            : `
        background-color: ${props.theme.highlight};
        height: ${props.theme.scale * props.theme.asideHeight}px;
    `}

    ${(props) =>
        props.theme.layout === 3 &&
        `
        position: absolute;
        bottom: 0;
        left: ${props.theme.scale * props.theme.contentPosLeft}px;
    `}
`;

const Header = styled.div`
    flex: 0 0 auto;
    position: relative;

    ${(props) =>
        props.theme.layout === 4
            ? `
        height: ${props.theme.scale * 394}px;
    `
            : `
        height: ${props.theme.scale * 140}px;
    `}

    ${(props) =>
        props.theme.header
            ? `
        background-color: ${props.theme.header};
    `
            : `
        background-color: transparent;
    `}
`;

const HeaderNews = styled.div`
    background-color: ${(props) => props.theme.highlight};
    color: ${(props) => props.theme.tickertext};
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    height: ${(props) => props.theme.scale * 120}px;
    padding: ${(props) => props.theme.scale * 40}px 0;
    font-size: ${(props) => props.theme.scale * 28}px;
    font-family: "MuseoSans-900", serif;
    box-sizing: border-box;
`;

/*
const HeaderTag = styled.div`
  color: ${(props) => props.theme.shade1};
  font-size: ${(props) => props.theme.scale * 20}px;
  font-family: "MuseoSans-900", serif;
  position: absolute;
  top: ${(props) => props.theme.scale * 180}px;
  left: ${(props) => props.theme.scale * 40}px;
`;
*/

const Logo = styled.img`
    position: absolute;
    z-index: 10;

    ${(props) =>
        props.theme.customer === "mersch"
            ? `
        height: ${props.theme.scale * 160}px; 
    `
            : `
        height: ${props.theme.scale * 72}px;
    `}

    ${(props) =>
        props.theme.layout === 0 &&
        `
        height: ${props.theme.scale * 80}px;
        left: ${props.theme.scale * 60}px;
        top: ${props.theme.scale * 32}px;
    `}

    ${(props) =>
        props.theme.customer === "useldange" && props.theme.layout === 0
            ? `
        top: ${props.theme.scale * 18}px; 
    `
            : `
        top: ${props.theme.scale * 32}px;
    `}

    ${(props) =>
        props.theme.customer === "esch-sur-sure" && props.theme.layout === 0
            ? `
        top: ${props.theme.scale * 18}px; 
        left: ${props.theme.scale * 18}px; 
    `
            : `
        top: ${props.theme.scale * 32}px;
    `}

    ${(props) =>
        props.theme.layout === 1 &&
        `
        height: ${props.theme.scale * 80}px;
        left: ${props.theme.scale * 60}px;
        top: ${props.theme.scale * 32}px;
    `}

    ${(props) =>
        props.theme.layout === 2 &&
        `
        height: ${props.theme.scale * 80}px;
        left: ${props.theme.scale * 60}px;
        top: ${props.theme.scale * 32}px;
    `}

    ${(props) =>
        props.theme.layout === 3 &&
        `
        left: ${props.theme.scale * 30}px;
        top: ${props.theme.scale * 30}px;
    `}

    ${(props) =>
        props.theme.layout === 4 &&
        `
        height: ${props.theme.scale * 120}px;
        left: ${props.theme.scale * 40}px;
        top: ${props.theme.scale * 40}px;
    `}

    ${(props) =>
        props.theme.logoHeight && 
        `
        height: ${props.theme.scale * props.theme.logoHeight}px; 
    `}

    ${(props) =>
        props.theme.logoLeft && 
        `
        left: ${props.theme.scale * props.theme.logoLeft}px; 
    `}
`;

const StyledDateTime = styled(DateTime)`
    position: absolute;
    width: ${(props) => props.theme.scale * 100}px;


    ${(props) =>
        (props.theme.layout === 0 ||
            props.theme.layout === 1 ||
            props.theme.layout === 2) &&
        `
        right: ${props.theme.scale * 48}px;
        top: ${props.theme.scale * 44}px;
    `}

    ${(props) =>
        props.theme.layout === 3 &&
        `
        right: ${props.theme.scale * 48}px;
        bottom: -${props.theme.scale * 96}px;
    `}

    ${(props) =>
        props.theme.layout === 4 &&
        `
        right: ${props.theme.scale * 40}px;
        top: ${props.theme.scale * 40}px;
    `}

    ${(props) =>
        props.theme.datetimeTop && 
        `
        top: ${props.theme.scale * props.theme.datetimeTop}px;
    `}

    ${(props) =>
        props.theme.datetimeRight && 
        `
        right: ${props.theme.scale * props.theme.datetimeRight}px;
    `}
`;

const SliderWrapper = styled.div`
    height: ${(props) => props.theme.scale * props.theme.videoHeight}px;
    width: ${(props) => props.theme.scale * props.theme.width}px;
    ${(props) => props.theme.sliderBackground && `background-color: ${props.theme.sliderBackground};` }
    overflow: hidden;
    flex: 0 0 auto;
    margin-bottom: ${(props) => props.theme.scale * 20}px;
    z-index: 100;
`;

const lbTop = css`
    top: ${(props) => props.theme.scale * props.theme.langTop}px;
`;

const lbBottom = css`
    bottom: ${(props) => props.theme.scale * 0}px;
`;

const LanguageToggleWrapper = styled(LanguageToggle)`
    position: absolute;
    width: 100%;
    left: 0;

    ${(props) =>
        props.theme.layout === 3 &&
        `
        width: auto;
    `}

    ${(props) => (props.$mr  || props.theme.layout === 3 ? lbBottom : lbTop)};

    ${(props) =>
        props.theme.layout === 4 &&
        props.$mr  &&
        `
        left: ${props.theme.scale * 110}px;
  `}
`;

const AppsAside = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: ${(props) => props.theme.scale * 660}px;
`;

const AppCode = styled.div`
    text-align: center;
    padding: ${(props) => props.theme.scale * 18}px 0;
`;

const AppDetail = styled.div`
    text-align: center;
    margin-bottom: ${(props) => props.theme.scale * 50}px;
    font-size: ${(props) => props.theme.scale * 14}px;
    color: ${(props) => props.theme.white};

    h2 {
        margin: 0;
        font-size: ${(props) => props.theme.scale * 18}px;
        font-family: "MuseoSans-900";
    }
`;

const AppleLogo = styled.img`
    width: ${(props) => props.theme.scale * 26}px;
    margin: 0 ${(props) => props.theme.scale * 4}px;
`;

const GooglePlayLogo = styled.img`
    width: ${(props) => props.theme.scale * 26}px;
    margin: 0 ${(props) => props.theme.scale * 4}px;
`;

const AppLogos = styled.div`
    display: flex;
    justify-content: center;
    padding: ${(props) => props.theme.scale * 20}px 0 0 0;
`;

const ActionButtonWrapper = styled(ActionButton)`
    position: absolute;

    text-align: center;
    z-index: 4;

    ${(props) =>
        props.theme.layout === 0 &&
        `
            top: ${props.theme.scale * 1140}px;
            right: 0;
            width: ${props.theme.scale * props.theme.asideWidth}px;
    `}

    ${(props) =>
        props.theme.layout === 1 &&
        `
            top: ${props.theme.scale * 1140}px;
            right: 0;
            width: ${props.theme.scale * props.theme.asideWidth}px;
      `}

    ${(props) =>
        props.theme.layout === 2 &&
        `
            bottom: ${props.theme.scale * 20}px;
            right: 0;
            width: ${props.theme.scale * props.theme.asideWidth}px;
      `}

    ${(props) =>
        props.theme.layout === 3 &&
        `
            bottom: ${props.theme.scale * 20}px;
            left: 0;
            width: ${props.theme.scale * 224}px;
      `}

    ${(props) =>
        props.theme.layout === 4 &&
        props.$mr  === false &&
        `
            bottom: ${props.theme.scale * 140}px;
            right: 0;
            width: ${props.theme.scale * 162}px;
      `}

    ${(props) =>
        props.theme.layout === 4 &&
        props.$mr  === true &&
        `
            bottom: ${props.theme.scale * 48}px;
            right: ${props.theme.scale * 170}px;
            width: ${props.theme.scale * 162}px;
            z-index: 1000;
      `}

      ${(props) =>
        props.theme.customer === 'helperknapp' &&
        props.$mr  === false &&
        `
            bottom: auto;
            right: 0;
            width: ${props.theme.scale * 162}px;
            z-index: 1000;
      `} 

      ${(props) =>
        props.theme.customer === 'helperknapp' &&
        props.$mr  === true &&
        `
            bottom: ${props.theme.scale * 20}px;
            right: ${props.theme.scale * 174}px;
            width: ${props.theme.scale * 162}px;
            z-index: 1000;
      `} 
`;

const mrTop = css`
    top: ${(props) => props.theme.scale * 1300}px;
`;

const mrBottom = css`
    bottom: ${(props) => props.theme.scale * 30}px;
`;

const MobiliteReduiteWrapper = styled(MobiliteReduite)`
    display: ${(props) => (props.theme.layout === 2 ? "none" : "block")};
    position: absolute;
    width: ${(props) => props.theme.scale * 120}px;
    ${(props) => (props.$mr  ? mrTop : mrBottom)};
    right: 0;
    text-align: center;
    z-index: 4;

    ${(props) =>
        props.theme.layout === 3 &&
        `
        display: none;
    `}

    ${(props) =>
        props.theme.layout === 4 &&
        `
            width: ${props.theme.scale * 162}px;
    `}
`;

const MrBarWrapper = styled(MobiliteReduite)`
    position: absolute;
    left: ${(props) => props.theme.scale * 10}px;
    bottom: ${(props) => props.theme.scale * 5}px;
    text-align: center;
`;

const WeatherWidgetWrapper = styled(WeatherWidget)`
    position: absolute;
    width: 100%;
    top: ${(props) => props.theme.scale * 24}px;
    left: 0;

    ${(props) =>
        props.theme.layout === 3 &&
        `
        display: none;
    `}

    ${(props) =>
        props.theme.layout === 4 &&
        `
        width: ${props.theme.scale * 300}px;
        height: ${props.theme.scale * 80}px;
        left: auto;
        right: ${props.theme.scale * 180}px;
        top: ${props.theme.scale * 40}px;
    `}
`;

const cTop = css`
    bottom: ${(props) => props.theme.scale * 0}px;
`;

const cBottom = css`
    ${(props) =>
        props.theme.layout === 4
            ? `
        bottom: ${props.theme.scale * 0}px;
    `
            : `
        bottom: ${props.theme.scale * 185}px;
    `}
`;

const Content = styled.div`
    background: ${(props) => props.theme.white};
    height: ${(props) => props.theme.scale * props.theme.contentHeight}px;
    width: 100%;

    position: absolute;
    ${(props) => (props.$mr  ? cBottom : cTop)};
`;

const ErrorWrapper = styled.div`
    background: ${(props) => props.theme.white};
    width: ${(props) => props.theme.width * props.theme.scale}px;
    height: ${(props) => props.theme.height * props.theme.scale}px;
    display: flex;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    text-align: center;
    align-items: center;
`;

const BottomBar = styled.div`
    background: ${(props) => props.theme.highlight};
    width: ${(props) => props.theme.width - 224 * props.theme.scale}px;
    height: ${(props) => 126 * props.theme.scale}px;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const MrBar = styled.div`
    background: ${(props) => props.theme.shade1};
    position: absolute;
    bottom: ${(props) => 40 * props.theme.scale}px;
    right: ${(props) => 200 * props.theme.scale}px;
    height: ${(props) => 100 * props.theme.scale}px;
    z-index: 999;
    color: ${(props) => props.theme.white};
    border-radius: 100px;

    ${(props) =>
        props.theme.hideLanguage
            ? `
            width: ${props.theme.scale * 200}px;
    `
            : `
            width: ${props.theme.scale * 520}px;
    `}
`;

class SmartTouch extends React.Component {
    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                { name: "FR", code: "fr" },
                { name: "DE", code: "de" },
                { name: "EN", code: "en" },
                { name: "LU", code: "lu" },
            ],
            translation: globalTranslations,
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage: "fr",
            },
        });

        const { dispatch } = this.props;

        const myStorage = window.localStorage;
        const urlParams = new URLSearchParams(window.location.search);
        const myCustomer = urlParams.get("customer");
        const myScreen = urlParams.get("screen");
        const isLocal = urlParams.get("local");

        if (myCustomer) {
            myStorage.setItem("customer", myCustomer);
        }
        if (myScreen) {
            myStorage.setItem("screen", myScreen);
        }
        if (isLocal) {
            myStorage.setItem("local", isLocal);
        }

        dispatch(
            setCustomer(
                myStorage.getItem("customer"),
                myStorage.getItem("screen"),
                myStorage.getItem("local")
            )
        );
        //dispatch(getConfig());
        //dispatch(pollStart());
    }

    componentDidUpdate(prevProps, prevState) {
        /*
        const { dispatch } = prevProps;

        // Check if home
        const match = matchPath(window.location.pathname, {
            path: "/home",
            exact: true,
            strict: false
        });

        if (match) {
            dispatch({ type: SET_HOME, home: true});
        } else {
            dispatch({ type: SET_HOME, home: false});
        }
        */
    }

    _getDefaultPath(layout, navigation) {
        let defaultPath = "/home";

        switch (layout) {
            case 0:
            case 1:
            case 2:
                for (let i = 0; i < navigation.length; i++) {
                    if (navigation[i].status === 2) {
                        defaultPath = `/${navigation[i].id}`;
                        break;
                    }
                }
                break;

            case 3:
                defaultPath = "/videoslider";
                break;

            case 4:
                defaultPath = "/ads";
                break;
            default:
                defaultPath = "/home";
        }

        return defaultPath;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { dispatch, navigation, layout } = nextProps;

        let defaultPath;

        if (navigation && layout !== undefined) {
            defaultPath = this._getDefaultPath(layout, navigation);
            dispatch(setDefaultPath(defaultPath));
        }

        return true;
    }

    render() {
        const {
            width,
            height,
            scale,
            layout,
            theme,
            news,
            mobiliteReduite,
            navigation,
            home,
            error,
            customer,
        } = this.props;

        if (error.number !== undefined)
            return (
                <ErrorWrapper>
                    <h1>{error.message}</h1>
                </ErrorWrapper>
            );

        let contentHeight,
            contentWidth,
            contentPosLeft,
            contentPosBottom,
            navTop,
            navIconTop,
            langTop,
            asideWidth,
            asideHeight,
            videoWidth,
            videoHeight,
            videoMaxHeight,
            agendaWidth,
            agendaHeight,
            documentWidth;

        switch (layout) {
            case 4:
                contentHeight = 1526;
                contentPosLeft = 0;
                contentPosBottom = 0;
                contentWidth = 960;
                videoWidth = 960;
                videoHeight = 540;
                videoMaxHeight = 500;
                navTop = 700;
                asideWidth = 162;
                asideHeight = 1920;
                navIconTop = 320;
                langTop = 50;
                agendaWidth = 459;
                agendaHeight = 382;
                documentWidth = 598;
                break;
            case 3:
                contentHeight = 954;
                contentWidth = 1696;
                contentPosLeft = 224;
                contentPosBottom = 126;
                videoWidth = 880;
                videoHeight = 495;
                videoMaxHeight = 455;
                home ? (navTop = 650) : (navTop = 150);
                asideWidth = 1696;
                asideHeight = 126;
                navIconTop = 60;
                home ? (langTop = 860) : (langTop = 360);
                agendaWidth = 424;
                agendaHeight = 477;
                documentWidth = 1376;
                break;
            case 2:
                home ? (contentHeight = 600) : (contentHeight = 936);
                contentWidth = 880;
                contentPosLeft = 0;
                contentPosBottom = 0;
                videoWidth = 880;
                videoHeight = 495;
                videoMaxHeight = 455;
                home ? (navTop = 650) : (navTop = 150);
                asideWidth = 144;
                asideHeight = 1280;
                navIconTop = 60;
                home ? (langTop = 860) : (langTop = 360);
                agendaWidth = 440;
                agendaHeight = 440;
                documentWidth = 560;
                break;
            default:
                contentHeight = 1035;
                contentPosLeft = 0;
                contentPosBottom = 0;
                contentWidth = 960;
                videoWidth = 960;
                videoHeight = 540;
                videoMaxHeight = 540;
                navTop = 700;
                asideWidth = 120;
                asideHeight = 1920;
                navIconTop = 320;
                langTop = 700;
                agendaWidth = 480;
                agendaHeight = 350;
                documentWidth = 640;
                break;
        }

        const themeSettings = {
            ...theme,
            scale: scale,
            width: width,
            height: height,
            layout: layout,
            customer: customer,
            contentHeight: contentHeight,
            contentWidth: contentWidth,
            contentPosLeft: contentPosLeft,
            contentPosBottom: contentPosBottom,
            videoHeight: videoHeight,
            videoWidth: videoWidth,
            videoMaxHeight: videoMaxHeight,
            navTop: navTop,
            asideWidth: asideWidth,
            asideHeight: asideHeight,
            navIconTop: navIconTop,
            langTop: langTop,
            agendaWidth: agendaWidth,
            agendaHeight: agendaHeight,
            documentWidth: documentWidth,
        };

        let logo = `${process.env.PUBLIC_URL}/images/logos/${theme.logo}`;

        let defaultPath;
        if (navigation && layout !== undefined) {
            defaultPath = this._getDefaultPath(layout, navigation);
        } else return null;

        let slider = (
            <SliderWrapper>
                <Slider></Slider>
            </SliderWrapper>
        );

        let nav = <NavigationWrapper hideLanguage={theme.hideLanguage} $mr={mobiliteReduite} />;

        let language;
        if (!theme.hideLanguage) language = <LanguageToggleWrapper $mr={mobiliteReduite} />;

        let apps = (
            <AppsAside>
                <AppCode>
                    <img src={images.home.appEsch} alt="App Esch" />
                </AppCode>
                <AppDetail>
                    <h2>Esch APP</h2>
                    Disponible sur
                    <br />
                    <b>
                        App Store &<br />
                        Google Play
                    </b>
                    <AppLogos>
                        <AppleLogo src={images.logos.appleStore} alt="Apple" />
                        <GooglePlayLogo
                            src={images.logos.googlePlay}
                            alt="Google"
                        />
                    </AppLogos>
                </AppDetail>
                <AppCode>
                    <img src={images.home.appVelok} alt="App Velok" />
                </AppCode>
                <AppDetail>
                    <h2>Vël’Ok APP</h2>
                    Disponible sur
                    <br />
                    <b>
                        App Store &<br />
                        Google Play
                    </b>
                    <AppLogos>
                        <AppleLogo src={images.logos.appleStore} alt="Apple" />
                        <GooglePlayLogo
                            src={images.logos.googlePlay}
                            alt="Google"
                        />
                    </AppLogos>
                </AppDetail>
            </AppsAside>
        );

        let weather = <WeatherWidgetWrapper />;

        let header = <Header />;

        let headerTop = "";
        
        if (customer === "helperknapp"){
            headerTop = (
                <Header>
                    <WeatherWidgetWrapper />
                    <StyledDateTime />
                    <HeaderNews>
                        <Marquee direction="left" delay={0} childMargin={0}>
                            <span>Wëllkomm an der Gemeng Helperknapp - Bienvenue à la Commune Helperknapp – Willkommen in der Gemeinde Helperknapp -  Bem-vindo ao município Helperknapp -  Welcome to the municipality Helperknapp - </span>
                        </Marquee>
                    </HeaderNews>
                </Header>
            );
        }else if (customer === "troisvierges"){
            headerTop = (
                <Header>
                    <WeatherWidgetWrapper />
                    <StyledDateTime />
                    <HeaderNews>
                        <Marquee direction="left" delay={0} childMargin={0}>
                            <span>Wëllkomm an der Gemeng Ëlwen - Bienvenue à la Commune Troisvierges – Willkommen in der Gemeinde Troisvierges -  Welcome to the municipality Troisvierges - </span>
                        </Marquee>
                    </HeaderNews>
                </Header>
            );
        }
        else{
            headerTop = (
                <Header>
                    <WeatherWidgetWrapper />
                    <StyledDateTime />
                    <HeaderNews>
                        {news && (
                            <Marquee direction="left" delay={0} childMargin={0}>
                                {news.map((article, i) => (
                                    <span key={i}>{article.title} - </span>
                                ))}
                            </Marquee>
                        )}
                    </HeaderNews>
                </Header>
            );
        }

        let datetime = <StyledDateTime />;

        let sliderOutput,
            navOutput,
            navOutputAside,
            languageOutput,
            appsOutput,
            weatherOutputAside,
            headerOutput,
            headerOutputTop,
            datetimeOutput;

        switch (layout) {
            case 4:
                navOutputAside = nav;
                languageOutput = language;
                headerOutputTop = headerTop;
                break;
            case 3:
                navOutput = nav;
                languageOutput = language;
                weatherOutputAside = weather;
                headerOutput = header;
                datetimeOutput = datetime;
                break;
            case 2:
                if (home) {
                    sliderOutput = slider;
                    appsOutput = apps;
                } else {
                    navOutput = nav;
                    languageOutput = language;
                }
                weatherOutputAside = weather;
                headerOutput = header;
                datetimeOutput = datetime;
                break;
            default:
                if (news) {
                    sliderOutput = slider;
                    navOutput = nav;
                    languageOutput = language;
                }
                weatherOutputAside = weather;
                headerOutput = header;
                datetimeOutput = datetime;
                break;
        }

        let mrBar, mrOutput;
        if (mobiliteReduite && layout === 4) {
            mrBar = (
                <MrBar>
                    <MrBarWrapper />
                    {!theme.hideLanguage && <LanguageToggleWrapper $mr={mobiliteReduite} />}
                </MrBar>
            );
            languageOutput = <></>;
            mrOutput = <></>;
        } else {
            mrOutput = <MobiliteReduiteWrapper $mr={mobiliteReduite} />;
        }

        let aside = <>
            <Aside>
                {weatherOutputAside}
                {navOutputAside}
                {languageOutput}
                {appsOutput}
                {mrOutput}
            </Aside>
            <ActionButtonWrapper
                $mr={mobiliteReduite}
            />
            {mrBar}</>;

        return (
            <Router>
                <InactivityCheck gotopath={defaultPath} />
                <ThemeProvider theme={themeSettings}>
                    <MainWrapper className="smart-touch">
                        <Logo
                            src={logo}
                            className="smart-touch__logo"
                            alt="logo"
                        />
                        {headerOutputTop}
                        <Wrapper>
                            <Main>
                                {headerOutput}
                                {datetimeOutput}
                                {sliderOutput}
                                {navOutput}
                                <Content $mr={mobiliteReduite}>
                                    <Routes>
                                        <Route path="/" element={<Navigate to={defaultPath} replace />} />
                                        <Route path="/ads" element={<Ads />} />
                                        <Route path="/home" element={<Home />} />
                                        <Route path="/videoslider" element={<VideoSlider />} />
                                        <Route path="/floorplan" element={<FloorPlan />} />
                                        <Route path="/mobility" element={<Mobility />} />
                                        <Route path="/eat_and_drink" element={<EatAndDrink />} />
                                        <Route path="/events" element={<Events />} />
                                        <Route path="/imxpoi" element={<IMXPoi />} />
                                        <Route path="/explore" element={<Explore />} />
                                        <Route path="/accomodations" element={<Accomodation />} />
                                        <Route path="/restaurants" element={<IMXRestaurants />} />
                                        <Route path="/whattodorestaurants" element={<WhattodoRestaurants />} />
                                        <Route path="/whattodo" element={<WhatToDo />} />
                                        <Route path="/photo" element={<Photo />} />
                                        <Route path="/trafic_info" element={<TraficInfo />} />
                                        <Route path="/information" element={<Information />} />
                                        <Route path="/weather" element={<Weather />} />
                                        <Route path="/hotels" element={<Hotels />} />
                                        <Route path="/jobs" element={<Jobs />} />
                                        <Route path="/ereider" element={<EReider />} />
                                        <Route path="/health" element={<Health />} />
                                        <Route path="/games" element={<Games />} />
                                        <Route path="/poll" element={<Poll />} />
                                        <Route path="/shops" element={<Shops />} />
                                        <Route path="/commune" element={<Commune />} />
                                        <Route path="/points_of_interest" element={<PointsOfInterest />} />
                                        <Route path="/rtl" element={<RTL />} />
                                        <Route path="/webview" element={<Webview />} />
                                    </Routes>
                                </Content>
                            </Main>
                            {layout !== 3 && (
                                <Routes>
                                    <Route path="/ads" element={<span></span>} />
                                    <Route path="*" element={aside} />
                                </Routes>
                            )}
                        </Wrapper>
                        {layout !== 4 && <ActionButtonWrapper />}
                        {layout === 3 && (
                            <BottomBar>{languageOutput}</BottomBar>
                        )}
                    </MainWrapper>
                </ThemeProvider>
            </Router>
        );
    }
}

const mapStateToProps = (state) => ({
    news: state.data.news,
    width: state.config.width,
    height: state.config.height,
    scale: state.config.scale,
    layout: state.config.layout,
    theme: state.config.theme,
    customer: state.config.customer,
    mobiliteReduite: state.config.mobiliteReduite,
    navigation: state.config.navigation,
    home: state.config.home,
    largeLayout: state.config.largeLayout,
    error: state.config.error,
});

export default withLocalize(connect(mapStateToProps)(SmartTouch));
