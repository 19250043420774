import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/lb";
import _ from "lodash";
import {withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import {Loading} from "../components/Layout";
import SwipeIndicator from "../components/SwipeIndicator";
import images from "../config/images";
import {SET_SWIPEINDICATOR} from "../redux/actions";

const InformationWrapper = styled.div`
    height: 100%;
    background-color: ${(props) => props.theme.white};
    overflow-y: auto;
`;

const InformationWrapperScroll = styled.div`
    height: 100%;
    background-color: ${(props) => props.theme.white};
    overflow-y: auto;
`;

const SwiperItem = styled.div`
    .swiper-slide__container {
        width: 100%;
        overflow: hidden;
    }
    &:focus {
        outline: none;
    }
`;

const StyledSlickSlider = styled(SlickSlider)`
    overflow-y: auto;

    &:focus {
        outline: none;
    }
    .slick-list,
    .slick-track {
        height: 100%;
    }
    .slick-slide {
        width: 100%;
        height: 100%;
    }
    .slick-prev {
        top: auto;
        left: ${(props) => props.theme.scale * 360}px;
    }
    .slick-next {
        top: auto;
        right: ${(props) => props.theme.scale * 360}px;
    }
    .slick-slide {
        background: white;
    }
`;

const NewsBlock = styled.div``;

const NewsImageWrapper = styled.div`
    max-height: ${(props) => props.theme.scale * 540}px;
    overflow: hidden;
`;

const NewsImage = styled.img`
    width: 100%;
    height: ${(props) => props.theme.scale * 540}px;
    object-fit: cover;
`;

const NewsDetail = styled.div`
    position: relative;
    text-align: center;
    overflow-y: auto;
    padding: ${(props) => props.theme.scale * 95}px;
`;

const NewsDetailLeftAligned = styled.div`
    position: relative;
    padding: ${(props) => props.theme.scale * 95}px;
`;

const LandscapeDetail = styled.div`
    position: relative;
    text-align: center;
    padding: ${(props) => props.theme.scale * 60}px
        ${(props) => props.theme.scale * 95}px;
    index-z: 1;
`;

const NewsDachzeil = styled.div`
    color: ${(props) => props.theme.black};
    font-size: ${(props) => props.theme.scale * 30}px;
    font-family: "MuseoSans-500", sans-serif;
`;

const NewsTitle = styled.div`
    font-size: ${(props) => props.theme.scale * 38}px;
    font-family: "MuseoSans-900", sans-serif;

    ${(props) =>
        props.theme.textDefault
            ? `
            color: ${props.theme.textDefault};
            `
            : `
            color: ${props.theme.black};
    `}
`;

const NewsHeader = styled.div`
    font-size: ${(props) => props.theme.scale * 22}px;
    font-family: "MuseoSans-300", sans-serif;
    margin: ${(props) => props.theme.scale * 20}px 0;

    ${(props) =>
        props.theme.textDefault
            ? `
            color: ${props.theme.textDefault};
            `
            : `
            color: ${props.theme.black};
    `}
`;

const NewsHeaderLeftAligned = styled.div`
    font-size: ${(props) => props.theme.scale * 22}px;
    font-family: "MuseoSans-300", sans-serif;
    margin: ${(props) => props.theme.scale * 20}px 0;
    text-align: left;

    ${(props) =>
        props.theme.textDefault
            ? `
            color: ${props.theme.textDefault};
            `
            : `
            color: ${props.theme.black};
    `}
`;

const NewsDate = styled.div`
    text-align: center;
    font-size: ${(props) => props.theme.scale * 20}px;
    font-family: "MuseoSans-300", sans-serif;
    margin: ${(props) => props.theme.scale * 20}px 0;

    ${(props) =>
        props.theme.textHighlight
            ? `
            color: ${props.theme.textHighlight};
            `
            : `
            color: ${props.theme.black};
    `}
`;

const NewsDateLeftAligned = styled.div`
    text-align: left;
    font-size: ${(props) => props.theme.scale * 20}px;
    font-family: "MuseoSans-300", sans-serif;
    margin: ${(props) => props.theme.scale * 20}px 0;

    ${(props) =>
        props.theme.textHighlight
            ? `
            color: ${props.theme.textHighlight};
            `
            : `
            color: ${props.theme.black};
    `}
`;

const RTLDetail = styled.div`
    position: relative;
    text-align: center;
    padding: ${(props) => props.theme.scale * 160}px
        ${(props) => props.theme.scale * 95}px
        ${(props) => props.theme.scale * 95}px
        ${(props) => props.theme.scale * 95}px;
`;

const RTLDachzeil = styled.div`
    color: ${(props) => props.theme.rtlred};
    font-size: ${(props) => props.theme.scale * 26}px;
    font-family: "MuseoSans-900", sans-serif;
    text-transform: uppercase;
`;

const RTLLogo = styled.img`
    position: absolute;
    top: ${(props) => props.theme.scale * 50}px;
    left: ${(props) => props.theme.scale * 90}px;
    height: ${(props) => props.theme.scale * 60}px;
`;

const RTLDate = styled.div`
    position: absolute;
    top: ${(props) => props.theme.scale * 50}px;
    right: ${(props) => props.theme.scale * 90}px;
    color: ${(props) => props.theme.black};
    font-size: ${(props) => props.theme.scale * 20}px;
    font-family: "MuseoSans-300", sans-serif;
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${(props) =>
        props.theme.scale * (props.theme.contentHeight / 2 - 45)}px;
    left: ${(props) =>
        props.theme.scale * (props.theme.contentWidth / 2 - 50)}px;
`;

const SwipeIndicatorWrapper2 = styled(SwipeIndicator)`
    position: absolute;
    top: ${(props) =>
        props.theme.scale * (props.theme.contentHeight / 2 + 250)}px;
    left: ${(props) =>
        props.theme.scale * (props.theme.contentWidth / 2 - 50)}px;
`;

const RenderNewsScroll = ({ activelanguage }) => {
    const news = useSelector((state) => state.data.news);
    const customer = useSelector((state) => state.config.customer);
    const swipeIndicator = useSelector((state) => state.config.swipeIndicator);
    const dispatch = useDispatch();

    if (!news) return null;

    let cLang = activelanguage.code;
    if (activelanguage.code === "lu") cLang = "lb";

    let newslist;
    if (customer === "ettelbruck")
        newslist = news.map(
            (item, i) => <NewsBlock key={i}>
                    <NewsImageWrapper>
                        <NewsImage src={item.imageurl} />
                    </NewsImageWrapper>
                    <NewsDetail>
                        {item.timestamp && (
                            <NewsDate>
                                {dayjs(item.timestamp)
                                    .locale(cLang)
                                    .format("DD MMMM YYYY")}
                            </NewsDate>
                        )}
                        <NewsTitle>{item.title}</NewsTitle>

                        <NewsHeaderLeftAligned

                            dangerouslySetInnerHTML={{
                                __html: (item.header === null ? '' : item.header.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")),
                            }}
                            
                        ></NewsHeaderLeftAligned>

                    </NewsDetail>
                </NewsBlock>,
            [SwiperItem]
        );
    else
        newslist = news.map(
            (item, i) => <NewsBlock key={i}>
                    <NewsImageWrapper>
                        <NewsImage src={item.imageurl} />
                    </NewsImageWrapper>
                    <NewsDetail>
                        {item.timestamp && (
                            <NewsDate>
                                {dayjs(item.timestamp)
                                    .locale(cLang)
                                    .format("DD MMMM YYYY")}
                            </NewsDate>
                        )}
                        <NewsTitle>{item.title}</NewsTitle>

                        <NewsHeader

                            dangerouslySetInnerHTML={{
                                __html: (item.header === null ? '' : item.header.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")),
                            }}
                            
                        ></NewsHeader>

                    </NewsDetail>
                </NewsBlock>,
            [SwiperItem]
        );

    const hideSwiper = () => {
        dispatch({ type: SET_SWIPEINDICATOR, state: false });
    };

    return (
        <InformationWrapperScroll onTouchStart={hideSwiper} onMouseDown={hideSwiper}>
            {newslist}
            {swipeIndicator && <SwipeIndicatorWrapper direction={'v'} />}
        </InformationWrapperScroll>
    );
}

const RenderNewsSlider = ({ activelanguage }) => {
    const news = useSelector((state) => state.data.news);
    const customer = useSelector((state) => state.config.customer);
    const swipeIndicator = useSelector((state) => state.config.swipeIndicator);
    const layout = useSelector((state) => state.config.layout);
    const dispatch = useDispatch();

    if (!news) return null;

    let cLang = activelanguage.code;
    if (activelanguage.code === "lu") cLang = "lb";

    let slidesToShow = 1;
    let slidesToScroll = 1;

    if (layout === 3) {
        slidesToShow = 2;
        slidesToScroll = 2;
    }
    const params = {
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        dots: false,
    };

    let slides = [];

    const newsDetail = (item, i) => {
        let output = "";

        switch (customer) {
            case "steinsel":
            case "ettelbruck":
                output = (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container">
                            <NewsBlock>
                                <NewsImageWrapper>
                                    <NewsImage src={item.imageurl} />
                                </NewsImageWrapper>
                                <NewsDetailLeftAligned>
                                    {item.timestamp && (
                                        <NewsDateLeftAligned>
                                            {dayjs(item.timestamp)
                                                .locale(cLang)
                                                .format("DD MMMM YYYY")}
                                        </NewsDateLeftAligned>
                                    )}
                                    <NewsTitle>{item.title}</NewsTitle>

                                    <NewsHeaderLeftAligned
                                        dangerouslySetInnerHTML={{
                                            __html: item.header.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,""),
                                        }}
                                    ></NewsHeaderLeftAligned>
                                </NewsDetailLeftAligned>
                            </NewsBlock>
                        </div>
                    </SwiperItem>
                );
                break;
            case "esch":
                output = (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container">
                            <NewsImageWrapper>
                                <NewsImage src={item.imageurl} />
                            </NewsImageWrapper>
                            <NewsDetail>
                                <NewsDachzeil>{item.dachzeil}</NewsDachzeil>
                                <NewsTitle>{item.title}</NewsTitle>
                                {item.timestamp && (
                                    <NewsDate>
                                        {dayjs(item.timestamp)
                                            .locale(cLang)
                                            .format("DD MMMM YYYY")}
                                    </NewsDate>
                                )}
                                <NewsHeader
                                    dangerouslySetInnerHTML={{
                                        __html: item.header.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,""),
                                    }}
                                ></NewsHeader>
                            </NewsDetail>
                        </div>
                    </SwiperItem>
                );
                break;
            case "hesperange":
            case "dippach":
            case "mondorf":
            case "leudelange":
            case "sanem":
            case "wincrange":
            case "waldbillig":
            case "petange":
            case "mamer":
            case "weiswampach":
            case "smarttouch":
            case "salon-des-communes":
            case "esch-sur-sure":
            case "grevenmacher":
                output = (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container">
                            <NewsBlock>
                                <NewsImageWrapper>
                                    <NewsImage src={item.imageurl} />
                                </NewsImageWrapper>
                                <NewsDetail>
                                    {item.timestamp && (
                                        <NewsDate>
                                            {dayjs(item.timestamp)
                                                .locale(cLang)
                                                .format("DD MMMM YYYY")}
                                        </NewsDate>
                                    )}
                                    <NewsTitle>{item.title}</NewsTitle>

                                    <NewsHeader
                                        dangerouslySetInnerHTML={{
                                            __html: item.header.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,""),
                                        }}
                                    ></NewsHeader>
                                </NewsDetail>
                            </NewsBlock>
                        </div>
                    </SwiperItem>
                );
                break;
            case "mersch":
            case "aerenzdall":
            case "useldange":
            case "vdl":
                let header = _.truncate(item.header, {
                    length: 450,
                    separator: /,? +/,
                });
                output = (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container">
                            <NewsImageWrapper>
                                <NewsImage src={item.imageurl} />
                            </NewsImageWrapper>
                            <LandscapeDetail>
                                {item.timestamp && (
                                    <NewsDate>
                                        {dayjs(item.timestamp)
                                            .locale(cLang)
                                            .format("DD MMMM YYYY")}
                                    </NewsDate>
                                )}
                                <NewsDachzeil>{item.dachzeil}</NewsDachzeil>
                                <NewsTitle>{item.title}</NewsTitle>
                                <NewsHeader
                                    dangerouslySetInnerHTML={{ __html: header.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,"") }}
                                ></NewsHeader>
                            </LandscapeDetail>
                        </div>
                    </SwiperItem>
                );
                break;
            default:
                let logo = images.logos.rtl;
                if (cLang === "fr") logo = images.logos.rtl5minutes;
                if (cLang === "en") logo = images.logos.rtlToday;

                output = (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container">
                            <NewsImageWrapper>
                                <NewsImage src={item.imageurl} />
                            </NewsImageWrapper>
                            <RTLDetail>
                                <RTLLogo src={logo} alt="RTL" />
                                <RTLDate>
                                    {dayjs(item.timestamp).format(
                                        "DD.MM.YYYY HH:mm"
                                    )}
                                </RTLDate>
                                <RTLDachzeil>{item.dachzeil}</RTLDachzeil>
                                <NewsTitle>{item.title}</NewsTitle>
                                <NewsHeader
                                    dangerouslySetInnerHTML={{
                                        __html: item.header.replace(/<a href="(.*?)">/g,"").replace(/<\/a>/g,""),
                                    }}
                                ></NewsHeader>
                            </RTLDetail>
                        </div>
                    </SwiperItem>
                );
        }

        return output;
    };

    if (news) {
        slides = news.map(
            (item, i) => {
                return newsDetail(item, i);
            },
            [SwiperItem]
        );
    }

    const hideSwiper = () => {
        dispatch({ type: SET_SWIPEINDICATOR, state: false });
    };

    return (
        <InformationWrapper onTouchStart={hideSwiper} onMouseDown={hideSwiper}>
            {news ? (
                <StyledSlickSlider {...params}>{slides}</StyledSlickSlider>
            ) : (
                <Loading />
            )} 
            {swipeIndicator && <SwipeIndicatorWrapper/>}
            {swipeIndicator && <SwipeIndicatorWrapper2 direction={'v'} />}
        </InformationWrapper>
    );
}

const Information = ({ activeLanguage }) => {
    const layout = useSelector((state) => state.config.layout);

    if (layout === 4)
        return <RenderNewsScroll activelanguage={activeLanguage}></RenderNewsScroll>
    else 
        return <RenderNewsSlider activelanguage={activeLanguage}></RenderNewsSlider>
};

export default withLocalize(Information);
